import axios from '@/plugins/axios-contact'

export default {

  async get (id) {
    return await axios.get(`answer-surveys/${id}`)
  },

  async saveAnswer (surveyId, questionId, data) {
    return await axios.post(`answer-surveys/${surveyId}/answer/${questionId}`, data, { surveyId })
  },

}
