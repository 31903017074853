import axios from 'axios'
import store from '@/store'
import router from '@/router'

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(function (config) {
  if (typeof store.state.contact.token !== 'undefined') {
    config.headers.Authorization = `Bearer ${store.state.contact.token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response && error.response.status === 401 && store.state.contact.token) {
      store.dispatch('logoutContact')

      if (error.config.surveyId) {
        router.push(`/responder-pesquisa/${error.config.surveyId}/login`)
      } else {
        router.push(`/download-recibos/login`)
      }
    }
    return Promise.reject(error)
  },
)

export default api
